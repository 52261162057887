import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import { useIntl } from "gatsby-plugin-intl";
import { connect } from "react-redux";
import { appRedirect } from "../redux/app/actions";
import {
	PageContentWrapper,
	PageContent,
	PageTwoColWrapper,
} from "../assets/styles/pageCommon";
import { colors } from "../assets/styles/vars";
import PageHeader from "../components/Common/PageHeader";
import { Breadcrumb, CustomButton } from "../assets/styles/globalCommon";
import { Link } from "gatsby";
import {
	InputBlock,
	StyledCustomFormSelect,
} from "../assets/styles/formCommon";
import { CSSTransition } from "react-transition-group";
import ModulesList from "../components/Common/ModulesList";
import { protegemosDatosModule } from "../data/global-data";
import {
	deporteOptions,
	DropdownIndicator,
	generoOptions,
	nivelOptions,
} from "../utils/forms";
import { authPerfilSaveInit } from "../redux/auth/actions";
import { SliderButton } from "@typeform/embed-react";

const MisDatosPage = ({
	authResolved,
	loggedIn,
	usuario,
	appRedirect,
	authPerfilSaveInit,
}) => {
	const intl = useIntl();

	useEffect(() => {
		if (authResolved && !loggedIn) appRedirect({ to: "/" });
	}, [authResolved, loggedIn]);

	// email

	const [localEmail, setLocalEmail] = useState("");
	const [isEditingEmail, setIsEditingEmail] = useState(false);
	const emailRef = useRef();

	useEffect(() => {
		if (isEditingEmail) emailRef?.current?.focus();
	}, [isEditingEmail]);

	// nombre

	const [localNombre, setLocalNombre] = useState("");
	const [isEditingNombre, setIsEditingNombre] = useState(false);
	const nombreRef = useRef();

	useEffect(() => {
		if (isEditingNombre) nombreRef?.current?.focus();
	}, [isEditingNombre]);

	// apellido

	const [localApellido, setLocalApellido] = useState("");
	const [isEditingApellido, setIsEditingApellido] = useState(false);
	const apellidoRef = useRef();

	useEffect(() => {
		if (isEditingApellido) apellidoRef?.current?.focus();
	}, [isEditingApellido]);

	// genero

	const [localGenero, setLocalGenero] = useState("");
	const [isEditingGenero, setIsEditingGenero] = useState(false);
	const generoRef = useRef();

	useEffect(() => {
		if (isEditingGenero) generoRef?.current?.focus();
	}, [isEditingGenero]);

	// fecha nacimiento

	const [localFechaNac, setLocalFechaNac] = useState("");
	const [isEditingFechaNac, setIsEditingFechaNac] = useState(false);
	const fechaNacRef = useRef();

	useEffect(() => {
		if (isEditingFechaNac) fechaNacRef?.current?.focus();
	}, [isEditingFechaNac]);

	// deporte

	const [localDeporte, setLocalDeporte] = useState("");
	const [isEditingDeporte, setIsEditingDeporte] = useState(false);
	const deporteRef = useRef();

	useEffect(() => {
		if (isEditingDeporte) deporteRef?.current?.focus();
	}, [isEditingDeporte]);

	// nivel

	const [localNivel, setLocalNivel] = useState("");
	const [isEditingNivel, setIsEditingNivel] = useState(false);
	const nivelRef = useRef();

	useEffect(() => {
		if (isEditingNivel) nivelRef?.current?.focus();
	}, [isEditingNivel]);

	//

	useEffect(() => {
		copyUserDataFromStore();
	}, []);

	useEffect(() => {
		copyUserDataFromStore();
	}, [usuario]);

	const copyUserDataFromStore = () => {
		// console.log("usuario", usuario);
		if (usuario) {
			setLocalEmail(usuario.email);
			setLocalNombre(usuario.nombre);
			setLocalApellido(usuario.apellido);
			setLocalGenero(usuario.genero);
			setLocalFechaNac(usuario.fechaNac);
			setLocalDeporte(usuario.deporte);
			setLocalNivel(usuario.nivel);
		}
	};

	//

	const saveField = (name, value) => {
		authPerfilSaveInit(intl.locale, {
			token: localStorage.getItem("token"),
			fields: {
				[name]: value,
			},
		});
	};

	return (
		<Layout>
			<Seo title="Mis Datos" />
			<section>
				<PageHeader title="Mis Datos" />

				<PageContentWrapper bgColor={colors.grayF2}>
					<PageContent width="1050px">
						<PageTwoColWrapper>
							<div className="pageCol leftCol">
								<Breadcrumb>
									<Link to="/account">Mi cuenta</Link>
									<a>&gt;</a>
									<a>Mis datos</a>
								</Breadcrumb>
								<p>
									🤓 Mantén tus datos actualizados para mejorar la
									experiencia dentro de la plataforma.
								</p>
								<br />

								<form>
									{/* EMAIL */}
									<InputBlock>
										<div className="inputInfoWrapper">
											<div className="inputInfo">
												<label htmlFor="email">Email</label>
												<CSSTransition
													in={!isEditingEmail}
													timeout={300}
													classNames="slideAnim"
													unmountOnExit
												>
													<p>{localEmail}</p>
												</CSSTransition>
											</div>
											<div className="inputAction">
												<CustomButton
													className="ghost withUnderline"
													onClick={(e) => {
														e.preventDefault();
														setIsEditingEmail(!isEditingEmail);
													}}
												>
													<p>
														{isEditingEmail
															? "Cancelar"
															: "Editar"}
													</p>
												</CustomButton>
											</div>
										</div>

										<CSSTransition
											in={isEditingEmail}
											timeout={300}
											classNames="slideAnim"
											unmountOnExit
										>
											<div className="inputWrapper">
												<div className="inputOut">
													<input
														id="email"
														name="email"
														type="email"
														value={localEmail}
														ref={emailRef}
														onChange={(e) =>
															setLocalEmail(e.target.value)
														}
													/>
												</div>
												<CustomButton
													type="button"
													onClick={() => {
														saveField("email", localEmail);
														setIsEditingEmail(false);
													}}
												>
													<p>Guardar</p>
												</CustomButton>
											</div>
										</CSSTransition>
									</InputBlock>

									{/* NOMBRE */}
									<InputBlock>
										<div className="inputInfoWrapper">
											<div className="inputInfo">
												<label htmlFor="nombre">Nombre</label>
												<CSSTransition
													in={!isEditingNombre}
													timeout={300}
													classNames="slideAnim"
													unmountOnExit
												>
													<p>{localNombre}</p>
												</CSSTransition>
											</div>
											<div className="inputAction">
												<CustomButton
													className="ghost withUnderline"
													onClick={(e) => {
														e.preventDefault();
														setIsEditingNombre(!isEditingNombre);
													}}
												>
													<p>
														{isEditingNombre
															? "Cancelar"
															: "Editar"}
													</p>
												</CustomButton>
											</div>
										</div>

										<CSSTransition
											in={isEditingNombre}
											timeout={300}
											classNames="slideAnim"
											unmountOnExit
										>
											<div className="inputWrapper">
												<div className="inputOut">
													<input
														id="nombre"
														name="nombre"
														type="text"
														value={localNombre}
														ref={nombreRef}
														onChange={(e) =>
															setLocalNombre(e.target.value)
														}
													/>
												</div>
												<CustomButton
													type="button"
													onClick={() => {
														saveField("nombre", localNombre);
														setIsEditingNombre(false);
													}}
												>
													<p>Guardar</p>
												</CustomButton>
											</div>
										</CSSTransition>
									</InputBlock>

									{/* APELLIDO */}
									<InputBlock>
										<div className="inputInfoWrapper">
											<div className="inputInfo">
												<label htmlFor="apellido">Apellido</label>
												<CSSTransition
													in={!isEditingApellido}
													timeout={300}
													classNames="slideAnim"
													unmountOnExit
												>
													<p>{localApellido}</p>
												</CSSTransition>
											</div>
											<div className="inputAction">
												<CustomButton
													className="ghost withUnderline"
													onClick={(e) => {
														e.preventDefault();
														setIsEditingApellido(
															!isEditingApellido,
														);
													}}
												>
													<p>
														{isEditingApellido
															? "Cancelar"
															: "Editar"}
													</p>
												</CustomButton>
											</div>
										</div>

										<CSSTransition
											in={isEditingApellido}
											timeout={300}
											classNames="slideAnim"
											unmountOnExit
										>
											<div className="inputWrapper">
												<div className="inputOut">
													<input
														id="apellido"
														name="apellido"
														type="text"
														value={localApellido}
														ref={apellidoRef}
														onChange={(e) =>
															setLocalApellido(e.target.value)
														}
													/>
												</div>
												<CustomButton
													type="button"
													onClick={() => {
														saveField("apellido", localApellido);
														setIsEditingApellido(false);
													}}
												>
													<p>Guardar</p>
												</CustomButton>
											</div>
										</CSSTransition>
									</InputBlock>

									{/* GENERO */}
									<InputBlock>
										<div className="inputInfoWrapper">
											<div className="inputInfo">
												<label htmlFor="genero">Género</label>
												<CSSTransition
													in={!isEditingGenero}
													timeout={300}
													classNames="slideAnim"
													unmountOnExit
												>
													<p>
														{localGenero
															? localGenero
															: "Selecciona una opción"}
													</p>
												</CSSTransition>
											</div>

											<div className="inputAction">
												<CustomButton
													className="ghost withUnderline"
													onClick={(e) => {
														e.preventDefault();
														setIsEditingGenero(!isEditingGenero);
													}}
												>
													<p>
														{isEditingGenero
															? "Cancelar"
															: "Editar"}
													</p>
												</CustomButton>
											</div>
										</div>

										<CSSTransition
											in={isEditingGenero}
											timeout={300}
											classNames="slideAnim"
											unmountOnExit
										>
											<div className="inputWrapper">
												<div className="inputOut">
													<StyledCustomFormSelect
														id="genero"
														name="genero"
														options={generoOptions}
														components={{ DropdownIndicator }}
														classNamePrefix="custom-form-select"
														isSearchable={false}
														value={generoOptions.filter(
															(genero) =>
																genero.label === localGenero,
														)}
														ref={generoRef}
														onChange={(e) =>
															setLocalGenero(e.label)
														}
													/>
												</div>
												<CustomButton
													type="button"
													onClick={() => {
														saveField("genero", localGenero);
														setIsEditingGenero(false);
													}}
												>
													<p>Guardar</p>
												</CustomButton>
											</div>
										</CSSTransition>
									</InputBlock>

									{/* FECHA NACIMIENTO */}
									<InputBlock>
										<div className="inputInfoWrapper">
											<div className="inputInfo">
												<label htmlFor="fechaNac">
													Fecha de Nacimiento
												</label>
												<CSSTransition
													in={!isEditingFechaNac}
													timeout={300}
													classNames="slideAnim"
													unmountOnExit
												>
													<p>
														{localFechaNac
															? localFechaNac
															: "--/--/--"}
													</p>
												</CSSTransition>
											</div>

											<div className="inputAction">
												<CustomButton
													className="ghost withUnderline"
													onClick={(e) => {
														e.preventDefault();
														setIsEditingFechaNac(
															!isEditingFechaNac,
														);
													}}
												>
													<p>
														{isEditingFechaNac
															? "Cancelar"
															: "Editar"}
													</p>
												</CustomButton>
											</div>
										</div>

										<CSSTransition
											in={isEditingFechaNac}
											timeout={300}
											classNames="slideAnim"
											unmountOnExit
										>
											<div className="inputWrapper">
												<div className="inputOut">
													<input
														id="fechaNac"
														name="fechaNac"
														type="date"
														max={
															new Date()
																.toISOString()
																.split("T")[0]
														}
														value={localFechaNac}
														ref={fechaNacRef}
														onChange={(e) =>
															setLocalFechaNac(e.target.value)
														}
													/>
												</div>
												<CustomButton
													type="button"
													onClick={() => {
														saveField("fechaNac", localFechaNac);
														setIsEditingFechaNac(false);
													}}
												>
													<p>Guardar</p>
												</CustomButton>
											</div>
										</CSSTransition>
									</InputBlock>

									{/* DEPORTE */}
									<InputBlock>
										<div className="inputInfoWrapper">
											<div className="inputInfo">
												<label htmlFor="deporte">Deporte</label>
												<CSSTransition
													in={!isEditingDeporte}
													timeout={300}
													classNames="slideAnim"
													unmountOnExit
												>
													<p>
														{localDeporte
															? localDeporte
															: "Selecciona una opción"}
													</p>
												</CSSTransition>
											</div>

											<div className="inputAction">
												<CustomButton
													className="ghost withUnderline"
													onClick={(e) => {
														e.preventDefault();
														setIsEditingDeporte(
															!isEditingDeporte,
														);
													}}
												>
													<p>
														{isEditingDeporte
															? "Cancelar"
															: "Editar"}
													</p>
												</CustomButton>
											</div>
										</div>

										<CSSTransition
											in={isEditingDeporte}
											timeout={300}
											classNames="slideAnim"
											unmountOnExit
										>
											<div className="inputWrapper">
												<div className="inputOut">
													<StyledCustomFormSelect
														id="deporte"
														name="deporte"
														options={deporteOptions}
														components={{ DropdownIndicator }}
														classNamePrefix="custom-form-select"
														isSearchable={false}
														value={deporteOptions.filter(
															(deporte) =>
																deporte.label === localDeporte,
														)}
														ref={deporteRef}
														onChange={(e) =>
															setLocalDeporte(e.label)
														}
													/>
												</div>
												<CustomButton
													type="button"
													onClick={() => {
														saveField("deporte", localDeporte);
														setIsEditingDeporte(false);
													}}
												>
													<p>Guardar</p>
												</CustomButton>
											</div>
										</CSSTransition>
									</InputBlock>

									{/* NIVEL */}
									<InputBlock>
										<div className="inputInfoWrapper">
											<div className="inputInfo">
												<label htmlFor="nivel">Nivel</label>
												<CSSTransition
													in={!isEditingNivel}
													timeout={300}
													classNames="slideAnim"
													unmountOnExit
												>
													<p>
														{localNivel
															? localNivel
															: "Selecciona una opción"}
													</p>
												</CSSTransition>
											</div>

											<div className="inputAction">
												<CustomButton
													className="ghost withUnderline"
													onClick={(e) => {
														e.preventDefault();
														setIsEditingNivel(!isEditingNivel);
													}}
												>
													<p>
														{isEditingNivel
															? "Cancelar"
															: "Editar"}
													</p>
												</CustomButton>
											</div>
										</div>

										<CSSTransition
											in={isEditingNivel}
											timeout={300}
											classNames="slideAnim"
											unmountOnExit
										>
											<div className="inputWrapper">
												<div className="inputOut">
													<StyledCustomFormSelect
														id="nivel"
														name="nivel"
														options={nivelOptions}
														components={{ DropdownIndicator }}
														classNamePrefix="custom-form-select"
														isSearchable={false}
														value={nivelOptions.filter(
															(nivel) =>
																nivel.label === localNivel,
														)}
														ref={nivelRef}
														onChange={(e) =>
															setLocalNivel(e.label)
														}
													/>
												</div>
												<CustomButton
													type="button"
													onClick={() => {
														saveField("nivel", localNivel);
														setIsEditingNivel(false);
													}}
												>
													<p>Guardar</p>
												</CustomButton>
											</div>
										</CSSTransition>
									</InputBlock>
								</form>

								<p>
									<Link to="/passrecovery">
										Haz clic aquí para modificar tu contraseña
									</Link>
								</p>
								<br />

								<p>
									Recuerda que no vamos a obligarte ni engañarte para
									que estés dentro de la cominidad de Fresh Snow, tus
									datos son tuyos a lo igual que tus decisiones. Puedes
									darte de baja cuando quieras y eliminaremos tu
									información de nuestra base de datos.
								</p>
								<br />

								<SliderButton
									id="Lua8Ih2R"
									hidden={{
										nombre: usuario?.nombre,
									}}
								>
									<CustomButton className="ghost withUnderline">
										<p>Darme de baja</p>
									</CustomButton>
								</SliderButton>
							</div>
							<div className="pageCol rightCol">
								<ModulesList items={protegemosDatosModule} />
							</div>
						</PageTwoColWrapper>
					</PageContent>
				</PageContentWrapper>
			</section>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	return {
		authResolved: state.auth.resolved,
		loggedIn: state.auth.loggedIn,
		usuario: state.auth.usuario,
	};
};

const mapDispatchToProps = {
	appRedirect,
	authPerfilSaveInit,
};

export default connect(mapStateToProps, mapDispatchToProps)(MisDatosPage);
